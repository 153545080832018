.plexus-navbar .navbar-brand {
    align-items: center;
    padding-left: 18px;
}

@media (min-width: 1024px) {
    .plexus-navbar {
        padding: 16px 0;
    }
}

.plexus-logo {
    height: 50px;
    width: 150px;
    vertical-align: middle;
}

body {
    color: #1b1b1b !important;
    font-family: Inter, sans-serif !important;
    overflow-x: hidden !important;
}

.plexus-navbar .navbar-item,
.plexus-navbar .navbar-link {
    color: #1b1b1b;
    font-size: 16px;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.icon {
    background-repeat: no-repeat !important;
}

.icon-twitter {
    background-image: url("../twitter.svg");
    height: 20px;
    width: 20px;
}

.icon-instagram {
    background-image: url("../instagram.svg");
    height: 20px;
    width: 20px;
}

.hero-main .hero-body {
    padding-bottom: 0 !important;
}

.hero.hero-main {
    background-color: #CBA8D2 !important;
    color: #f2f0e6 !important;
    max-height: 950px;
}

@media (min-width: 1024px) {
    .hero.hero-main {
        max-height: 700px;
    }
}

.hero-main h1 {
    margin-bottom: 24px !important;
}

@media (min-width: 1024px) {
    .hero-main h1 {
        margin-top: 120px;
    }
}

h1 {
    font-size: 60px !important;
    font-weight: 800 !important;
    line-height: 64px !important;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h3 {
    font-size: 28px;
    font-weight: 800;
    line-height: 32px;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

p.body1 {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

p.body2 {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;;
}

.button {
    background-color: #CBA8D2 !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600 !important;
    text-decoration: none !important;
}

.button-white {
    background-color: #f2f0e6 !important;
    color: #CBA8D2 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    padding: 0 20px !important;
}

.hero-main .mobile-screenshots {
    position: relative;
}

.hero-main .mobile-screenshots .screenshot1 {
    margin-left: -50px;
    margin-top: 55px;
}

@media (min-width: 1024px) {
    .hero-main .mobile-screenshots .screenshot1 {
        margin-left: 0px;
        margin-top: 46px;
        width: 320px;
    }
}

.hero-main .mobile-screenshots .screenshot2 {
    margin-left: -32px;
    position: absolute;
    top: 0px;
}

@media (min-width: 1024px) {
    .hero-main .mobile-screenshots .screenshot2 {
        width: 300px;
        padding-top: 30px;
    }
}

.hero-main .mobile-screenshots img {
    transform: rotate(22.5deg);
    width: 218px;
}

h2 {
    font-size: 40px !important;
    font-weight: 800 !important;
    line-height: 44px;
}

.section.why-plexus h2 {
    margin-bottom: 16px;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.download h2 {
    margin-top: 32px;
    margin-bottom: 32px;
}

.download .message {
    background: #f6f6f6 !important;
    margin-top: 16px !important;
    border-radius: 12px;
}

.download .message-body {
    border: none !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.download-window.columns {
    background-color: #f2f0e6;
    border-radius: 12px;
    height: 300px;
    margin: 0;
    margin-bottom: 25px;
}

.download-window .column {
    height: 100% !important;
    padding-bottom: 0 !important;
}

.download-window .download-window-wrapper {
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    justify-content: flex-end !important;
    margin: 0 auto !important;
}

.download .button {
    margin: 15px 30px !important;
}

p a {
    color: #CBA8D2;
}

.footer {
    background-color: #3C3744 !important;
    color: #e9e9e9;
    font-size: 16px;
    line-height: 22px;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.footer strong {
    color: #E9E9E9 !important;
    display: block !important;
    font-weight: 600 !important;
    margin-bottom: 12px !important;
}

.footer a {
    color: #E9E9E9 !important;
}

.footer a:hover {
    text-decoration: underline !important;
}

.section.features {
    background-color: #f6f6f6;
}

.section.features .box {
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 12%), 0px 0px 2px rgb(0 0 0 / 8%);
    height: 100%;
    padding: 30px;
}

.section.features img {
    width: 280px;
}

@media (min-width: 768px) {
    .section.features img {
        width: 350px;
    }
}

.section.features h3 {
    color: #1b1b1b;
    font-size: 28px;
    font-weight: 800;
    line-height: 32px;
    margin-top: 18px;
    margin-bottom: 18px;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.mainbox {
    background-color: #CBA8D2;
    margin: auto;
    height: 758px;
    width: 100%;
    position: relative;
}

.err {
    color: #f2f0e6;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 20%;
    top: 8%;
}

.far {
    position: absolute;
    font-size: 8.5rem;
    left: 42%;
    top: 15%;
    color: #f2f0e6;
}

.err2 {
    color: #f2f0e6;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 68%;
    top: 8%;
}

.msg {
    text-align: center;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    position: absolute;
    left: 16%;
    top: 45%;
    width: 75%;
    color: #f2f0e6;
}

.mainbox a {
    text-decoration: none;
    color: black;
}

.mainbox a:hover {
    text-decoration: underline;
}